<template>
  <div class="login">
    <div class="loginbox">
      <img src="../assets/image/white_logo.png" alt="">
      <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
        <el-tab-pane label="使用秉匠账户登录" name="first">
          <!-- 账户登录 -->
          <el-form ref="form" :model="form">
            <el-form-item >
              <el-input v-model="form.email" placeholder="电子邮箱地址"></el-input>
            </el-form-item>
             <el-form-item >
              <el-input v-model="form.password" placeholder="密码" :type="passw">
                <i slot="suffix" :class="icon" @click="showPass"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="checkedPass">
              <el-checkbox v-model="checkedPass">记住我</el-checkbox>
              <span class="span">忘记密码</span>
            </el-form-item>
            <el-form-item class="loginbtn">
              <span class="">现在登录</span>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="使用手机验证码登录" name="second">
          <el-form ref="mobileForm" :model="mobileForm">
            <el-form-item >
              <el-input v-model="mobileForm.mobile" placeholder="请输入手机号码"></el-input>
            </el-form-item>
             <el-form-item class="validate">
              <el-input v-model="mobileForm.validate" placeholder="请输入验证码"></el-input>
              <!-- <el-button type="primary">获取验证码</el-button> -->
              <div class="keyid">
                <span  style="display:block">获取验证码</span>
                <!-- <span v-else style="background:#ccc;height: 100%;width: 100%;display: block;">{{ countdownreg }}s后可重试</span> -->
              </div>
            </el-form-item>
            <el-form-item class="loginbtn">
              <span class="">现在登录</span>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <p class="p">隐私政策</p>
      <div class="register">没有秉匠账户？ <span @click="goregister">注册</span></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
      icon:"el-input__icon el-icon-view",
      passw: 'password',
      form: {
        email: '',
        password: ''
      },
      mobileForm: {
        mobile: '',
        validate: ''
      },
      checkedPass: false
    }
  },
  methods: {
    handleClick () {

    },
    showPass(){ //点击图标是密码隐藏或显示
      if( this.passw=="text"){
        this.passw="password"
        this.icon="el-input__icon el-icon-view"
      }else {
        this.passw="text"
        this.icon="el-input__icon el-icon-loading"
      }
    },
    goregister () {
      this.$router.push('/register')
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.login{
  background-color: #121212;
  color: #fff;
  height: 100%;
  width: 100%;
  padding-top: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  .loginbox{
    width: 450px;
    background: #202020;
    margin: 0px auto;
    border-radius: 5px;
    padding: 60px 0 60px 0;
    img{
      width: 15%;
      margin: 0px auto;
      display: block;
    }
    p{
      font-size: 12px;
      margin-left: 50px;
      margin: 10px 50px 5px;
    }
    /deep/ .el-tabs{
      width: 350px;
      margin: 0 auto;
      color: #fff;
      margin-top: 10px;
      .el-tabs__item{
        color: #737373;
      }
      .el-tabs__item.is-active{
        color: #FFF;
      }
      .el-tabs__active-bar, .el-tabs__nav-wrap::after{
        display: none;
      }
    }
    .p{
      margin-top: 20px;
      font-size: 14px;
    }
    .register{
      color: #868686;
      font-size: 14px;
      margin-top: 30px;
      span{
        color: #fff;
        margin-left: 50px;
      }
      span:hover{
        color: #00a0ea;
        cursor: pointer;
      }
    }
    /deep/ .el-form{
      .el-input__inner{
        background-color: #2b2b2b;
        color:#fff;
        height: 50px;
        line-height: 50px;
        border: none;
      }
      .checkedPass{
        .el-checkbox{
          float: left;
        }
        .span{
          float: right;
          color: #868686;
        }
      }
      .el-checkbox__inner{
        background-color: #454545;
        border: none;
      }
      .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #fff;
      }
      .loginbtn{
        background-color: #191919;
        height: 50px;
        line-height: 50px;
        color: #868686;
        padding: 10px 0;
        border-radius: 5px;
        margin-top: 10px;
      }
      .validate .el-input{
        width: calc(100% - 140px);
        float: left;
      }
      .validate .keyid{
        float: right;
        width: 120px;
        height: 50px;
        line-height: 50px;
        background-color: #00a0ea;
        color: #fff;
        font-weight: 700;
        text-align: center;
        border-radius: 3px;
      }
    }
  }
}
</style>
